/** @jsx jsx */
import React, { useState, useRef } from "react"
import { jsx, Flex, Input, Select } from "theme-ui"
import { graphql } from "gatsby"
import { useTranslation, Link } from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import Columns from "../components/columns"
import Result from "../components/@atk-crit-result"
import { Note, NotesChart } from "../components/@atk-crit-notes"
import { FocusChara } from "../components/@showcase-avatar-analysis"

const Page = () => {
	const {t} = useTranslation()
		, [status, setStatus] = useState("ATK")
		, [base, setBase] = useState()
		, [add, setAdd] = useState()
		, [critRate, setCritRate] = useState()
		, [critDmg, setCritDmg] = useState()
		, [arr,setArr] = useState([])
		, notes = useRef()
		, click = () => {
			const dom = document.getElementsByClassName(`details-content`)[0]
				, check = document.getElementsByClassName(`details-checkbox`)[0]
			setArr([{
				"status": status,
				"base": Number(base),
				"add": Number(add),
				"cr": Number(critRate),
				"cd": Number(critDmg),
				"avg": avg(Number(base), Number(add), Number(critRate/100), Number(critDmg/100)),
				"crit": avg(Number(base), Number(add), 1, Number(critDmg/100)),
			}].concat(arr))
			check.checked = false
			dom.style.height = `0px`
		}
		, remove = (index) => {
			const dom = document.getElementsByClassName(`details-content`)[0]
				, check = document.getElementsByClassName(`details-checkbox`)[0]
			setArr(arr.filter((e,i) => i !== index))
			if(!arr[1]) {
				if(check && dom) {
					check.checked = true
					dom.style.height = dom.scrollHeight +`px`
				}
			}
		}
	return (
		<Layout title={t(`ATK CRIT Ratio Checker`)}>
			<Columns>
				<div sx={{
					fontSize: [2,2,3],
					"> div:not(:first-of-type)": {
						ml: 4, mt: 1, mb: 3,
					},
					"input": {
						fontSize: 4,
						display: `inline-block`,
						width: `4.5em`,
						py: 0,
						px: 2,
					},
				}}>
					<Flex>
						<Select
							onChange={e=>setStatus(e.target.value)}
							sx={{
								textAlign: `center`,
								border: 0,
								borderBottom: `solid 1px`,
								borderColor: `border`,
								borderRadius: 0,
								p: 0,
								mr: `.5em`,
							}}
							arrow={<div></div>}
							defaultValue="ATK"
						>
							<option value="HP">{t(`HP`)}</option>
							<option value="ATK">{t(`ATK`)}</option>
							<option value="DEF">{t(`DEF`)}</option>
						</Select>{t(`(White + Green)`)}
					</Flex>
					<div>
						<Input type="number" onChange={e=>setBase(e.target.value)} /> + <Input type="number" onChange={e=>setAdd(e.target.value)} />
					</div>
					{t(`CRIT Rate`)}
					<div>
						<Input type="number" onChange={e=>setCritRate(e.target.value)} />
					</div>
					{t(`CRIT DMG`)}
					<div>
						<Input type="number" onChange={e=>setCritDmg(e.target.value)} />
					</div>
				</div>
				<div>
					<Result
						t={t}
						status={status}
						base={Number(base)}
						add={Number(add)}
						critRate={Number(critRate/100)}
						critDmg={Number(critDmg/100)}
						onClick={click}
					/>
				</div>
			</Columns>
			{ !(arr[0]) ? null : (<>
				<h2>{t(`Note`)}</h2>
				<Columns mb={0}>
					<div sx={{mr: [0,4],}}>
						<div ref={notes}>{arr.map((e,i)=>(<React.Fragment key={i}>
							<Note
								data={e} status={status}
								t={t} remove={remove} i={i}
								label={`#`+ (arr.length - i)}
							/>
						</React.Fragment>))}</div>
					</div>
					<NotesChart
						data={arr}
						labels={arr.map((e,i)=>`#`+ (arr.length - i))}
						legends={[t(`Avg.`), t(`CRIT`)]}
						notes={notes.current}
					/>
				</Columns>
			</>)}
			<h2>{t(`Supplement`)}</h2>
			<FocusChara />
			<div sx={{mt:`.5em`, mb:`.75em`}}>{t(`For the above characters, we recommend using the following tool:`)}</div>
			<Link to={`/avg-dmg`}>{t(`Average Damage Calculator`)}</Link>
			<h2>{t(`Reference Links`)}</h2>
			<a href="https://blog.luis.fun/gi-atk-crit-ratio/">
				{t(`Ideal ratio of ATK and CRIT (ja)`)}
			</a>
		</Layout>
	)
}
export default Page

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["common","atk-crit"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`

const avg = (base, add, critRate, critDmg) => (
		((base+add)*(1+clip01(critRate)*critDmg)).toFixed(1)
	)
	, clip01 = num => (num < 0) ? 0 : ((num > 1) ? 1 : num)
